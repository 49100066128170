
<template>
<div v-show="screenSearch" class="screenSearch">
    <van-popup v-model="screenSearch" position="right" :overlay="true" class="screenSearchPop" get-container="body" style="height:100%;">
        <ContentItem :sysBoxValue="sysBoxValue" @screenSearchClick="screenSearchClick" @getListData="getListData" :listLabels="listLabels"></ContentItem>
    </van-popup>
</div>
</template>
<script>
import ContentItem from './ContentItem/index.vue'
export default {
name: 'screenBox',
    props: {
        sysBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        listLabels: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data () {
        return {
            screenSearch: false
        }
    },
    created () {
    },
    mounted () {
    },
    watch: {
    },
    computed: {
    },
    methods: {
        searchScreenClick() {
            this.screenSearch = true
        },
        screenSearchClick(type) {
            this.screenSearch = type
        },
        getListData(obj) {
            this.$emit('getListData', obj)
        }
    },
    components: {
        'ContentItem': ContentItem
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
