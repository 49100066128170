<template>
    <div class="clueList">
            <div class="scrollBox">
                <scroll v-if="!loading" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                    <div class="screenBoxs" ref="screenBoxs">
                        <screen-box @getListData="getListDatas" @sortSeachClick="sortSeachClick" @searchScreenClick="$refs.searchScreen.searchScreenClick()" class="screens"></screen-box>
                    </div>
                    <div class="moduleCodePage">
                        <listView :listData="listData" :countNum="page.total" :sysBoxValue="sysBoxValue"></listView>
                    </div>
                </scroll>
                <loading-new :loading="loading"></loading-new>
            </div>
            <search-screen ref="searchScreen" @getListData="getListDatas" :sysBoxValue="sysBoxValue"  :listLabels="listLabels"></search-screen>
    </div>
</template>
<script>
import listView from './listView/index.vue'
import screenBox from './screenBox/index.vue'
import searchScreen from './screenBox/searchScreen/index.vue'
import Scroll from '@/components/Scroll/index'
import loadingNew from '@/page/Client/CustPublic/LoadingVue/index.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'custList',
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // 加载更多
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385'), // 没有更多数据了
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],

            msg: 'custList',
            listData: [],
            sort: 'createDate',
            page: {
                total: 0,
                from: 0,
                size: 10
            },
            loading: true,
            otherObj: {},
            pageUrl: '',
            listLabels: []
        }
    },
    created() {
    },
    computed: {
        ...mapGetters(['routParameters']),
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    mounted() {
        this.pageUrl = this.$route.path
        this.unit()
    },
    methods: {
        ...mapMutations({// 用于清空参数
            set_routParameters: 'SET_ROUTPARAMETERS'
        }),
        toOtherPage(url) {
            this.$router.push({ path: url, query: this.g_setQuery() })
        },
        async sortSeachClick(fieldName) {
            this.sort = fieldName
            this.listData = await this.getListData()
        },
        async getListDatas(obj) {
            this.listData = await this.getListData(obj)
        },
        async getListData(obj) {
            let data = {
                moduleCode: 'PS003',
                order: 'desc',
                size: this.page.size,
                sort: this.sort,
                searchType: 'list'
            }
            if (obj) {
                data = Object.assign(data, obj)
                this.otherObj = obj
                this.page.from = 0
            } else {
                data = Object.assign(data, this.otherObj)
            }
            data.from = this.page.from
            let res = await this.axios.post(this.Global.config.apiBaseURL + this.Global.api.v2.document_newStructSearch_do, data)
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.page.total = res.data.data.totalNum
                return res.data.data.list
            } else {
                this.$toast.fail(res.data.msg)
                return []
            }
        },
        unit(loading) {
            if (!loading) {
                this.loading = true
            }
            var p1 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'PS003',
                    order: 'desc',
                    from: loading ? 0 : this.page.from,
                    size: loading ? this.page.from + this.page.size : this.page.size,
                    sort: this.sort,
                    searchType: 'list'
                }

                if (JSON.stringify(this.routParameters) != '{}') {
                    this.otherObj = {}
                    this.otherObj = Object.assign(this.otherObj, this.routParameters)
                    data = Object.assign(data, this.otherObj)
                    this.set_routParameters({})
                } else {
                    data = Object.assign(data, this.otherObj)
                }
                this.axios.post(this.Global.config.apiBaseURL + this.Global.api.v2.document_newStructSearch_do, data).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        console.log(res.data.data)
                        resolve(res.data.data.list)
                        this.page.total = res.data.data.totalNum
                    } else {
                        if (!loading) {
                            this.loading = false
                        }
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    if (!loading) {
                        this.loading = false
                    }
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            var p2 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.label_get, { params: { searchType: 'list', moduleCode: 'PS003' } }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        if (!loading) {
                            this.loading = false
                        }
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    if (!loading) {
                        this.loading = false
                    }
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2]).then((results) => {
                this.listData = results[0]
                this.listLabels = results[1]
                if (!loading) {
                    this.loading = false
                }
            })
        },
        async onPullingDown() {
            this.page.from = 0
            this.checkedItems = []
            this.listData = await this.getListData()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            if (this.listData.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.listData.length < this.page.total) {
                this.page.from += 10
                let listData = await this.getListData()
                this.listData = this.listData.concat(listData)
                this.$nextTick(() => {
                    if (this.listData.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.path === this.pageUrl) {
                if (from.path.indexOf('/client/detail/') == -1) {
                    this.otherObj = {}
                }
                this.unit(true)
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            // this.rebuildScroll()
        }
    },
    components: {
        'listView': listView,
        'screen-box': screenBox,
        'Scroll': Scroll,
        'search-screen': searchScreen,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
