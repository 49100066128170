<template>
    <div class="custListView">
        <div v-if="listData.length == 0">
            <!--暂无客户-->
            <nodata :title="$t('mx_mobile.Client.1585629076534')" img="noCustomer"></nodata>
            <div style="text-align:center">
                <!-- <van-button type="danger" size="small">马上创建</van-button> -->
            </div>
        </div>
        <template v-else>
            <!-- 数据 -->
            <div class="countBox" v-if="!countNumNo">{{$t('mx_mobile.Client.1585117918604')}}<span class="count">{{countNum}}</span>{{$t('mx_mobile.common.1665811495851')}}</div>
            <div class="list clientList" v-for="(item, index) in listData" :key="index" :title="item.custName + ''" @touchstart="touchstart(index)" @touchend="touchend(index)" @click="thisToOtherPage(item)">
                <div class="listBox">
                    <div class="listTitle">
                        <!-- 客 -->
                        <div v-if="item['custId']" class="Tag custTag">{{$t('mx_mobile.common.1665811542372')}}</div>
                        <div v-else class="Tag strangeTag">
                            <!-- 陌 -->
                            {{$t('mx_mobile.common.1665819404439')}}
                        </div>
                        <div class="ellipsis">{{item.custName}}</div>
                    </div>
                    <div class="listContent">
                        <div class="contentSpan">
                            <!-- 国家/地区 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665812520076')}}</span>
                            <div class="contentTxt ellipsis">{{item._convertData.countryId}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 发件邮箱 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665812667125')}}</span>
                            <div class="contentTxt ellipsis">{{item.mailAddress}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 来源渠道 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665812798095')}}</span>
                            <div class="contentTxt ellipsis">{{custSourceMap[item._convertData.custSource]}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 来源阶段 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665813435510')}}</span>
                            <div class="contentTxt ellipsis">{{item._convertData.intentLevel}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 线索编号 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665813488934')}}</span>
                            <div class="contentTxt ellipsis">{{item.intentCode}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 线索接收人 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665813538051')}}</span>
                            <div class="contentTxt ellipsis">{{item._convertData.ownerCtId}}</div>
                        </div>
                        <div class="contentSpan">
                            <!-- 创建时间 -->
                            <span class="contentTitle">{{$t('mx_mobile.common.1665813574570')}}</span>
                            <div class="contentTxt ellipsis">{{item.createDate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <otDistribution @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otDistribution" :moduleCode="moduleCode" optCode="otDistribution" :item="item"></otDistribution>
            <otReceive @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otReceive" :moduleCode="moduleCode" optCode="otReceive" :item="item"></otReceive>
        </template>
    </div>
</template>
<script>
import titleLabel from './titleLabel/index'
import otDistribution from '@/page/Client/CustPublic/optVue/otDistribution/index'
import otReceive from '@/page/Client/CustPublic/optVue/otReceive/index'

export default {
    name: 'custListView',
    props: {
        countNum: {
            type: Number,
            default: 0
        },
        countNumNo: {
            type: Boolean,
            default: false
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
            moduleCode: 'BF001',
            item: {},
            isRoute: this.$router.currentRoute.path || '',
            refsData: ''
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
        custSourceMap() {
            let obj = {}
            this.sysBoxValue.forEach((element) => {
                if (element.dictCode == 8) {
                    try {
                        let dictItems = element.dictItems
                        dictItems.forEach(ele => {
                            obj[ele.dictItemCode] = ele.itemName
                        })
                    } catch (error) {
                        console.error(error)
                    }
                }
            })
            return obj
        }
    },
    methods: {
        setTitle() {
        },
        setMenu() {
            this.$emit('RefreshGetData')
        },
        // 权限校验
        rightCheck(item) {
            let _this = this
            let obj = {
                moduleCode: 'BF001',
                identFieldValue: item.custId,
                optCode: _this.refsData
            }

            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: _this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_rightCheck_do, { params: obj }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    // 关闭客户查重的搜索框
                    _this.$emit('showScreen')

                    if (_this.refsData == 'otDistribution' || _this.refsData == 'otReceive') {
                        _this.$toast.clear()
                        _this.$refs[_this.refsData].openWindow()
                    }
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        getData(type) {
            if (type == 'close') {
                this.$router.back()
                return false
            }
        },
        touchend(index) {
            let dom = document.getElementsByClassName('clientList')
            dom[index].style.backgroundColor = 'white'
        },
        touchstart(index) {
            let dom = document.getElementsByClassName('clientList')
            dom[index].style.backgroundColor = '#fcf2f3'
        },
        returnAllSysBox(item) {
            let list = []
            let custLevel, custSource, tradeType
            custLevel = this.returnSysBoxValue('9', item.custLevel)
            custSource = this.returnSysBoxValue('8', item.custSource)
            tradeType = this.returnSysBoxValue('18', item.tradeType)
            if (custLevel != '') {
                list.push(custLevel)
            }
            if (custSource != '') {
                list.push(custSource)
            }
            if (tradeType != '') {
                list.push(tradeType)
            }
            return list.join('、')
        },
        returnSysBoxValue(dictCode, dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.dictItems.forEach(items => {
                        if (items.dictItemCode == dictItemCode) {
                            itemName = items.itemName
                        }
                    })
                }
            })
            return itemName
        },
        toOtherPage(url) {
            this.$router.push({ path: url })
        },
        // 跳转页面详情
        thisToOtherPage(item) {
            let url = '/sale/tabs/list/PS003/' + item.intentId
            this.toOtherPage(url)
        }
    },
    watch: {
        $route(to, from) {
            if (to.path) {
                this.isRoute = to.path
            }
        }
    },
    components: {
        'title-label': titleLabel,
        'otDistribution': otDistribution,
        'otReceive': otReceive
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
