
<template>
    <div class="screenSearchPop">
        <div class="searchSetBox">
            <template v-for="(item, index) in searchData">
                <div class="searchSet" :key="index" v-if="item.fieldGroup == 0 && item.controlTypeId==2">
                    <div class="title">{{item.cnFieldCaption}}</div>
                    <searchLabelsV ref="searchLabelsV" :list="item" moduleCode="PS003" :sysBoxValue="sysBoxValue"></searchLabelsV>
                </div>
            </template>
            <div class="searchSet">
                <div class="title">{{tags.cnFieldCaption}}</div>
                <tag moduleCode="BF001" ref="tag" :list="tags" :listLabels="listLabels"></tag>
            </div>
        </div>
        <div class="footer">
            <div class="leftBut" @click="clear()">
                <!-- 重置 -->
                {{$t('mx_mobile.Client.1585286717723')}}
            </div>
            <div class="rightBut" @click="submit()">
                <!-- 完成 -->
                {{$t('mx_mobile.Client.1585286721404')}}
            </div>
        </div>
    </div>

</template>
<script>
import searchLabelsV from '../Vue/searchLabelsV.vue'
import PersonnelSelection from '@/page/Mail/MailPublic/PersonnelSelection/index'
import MXEventBus from '@/libs/eventBus'
import tag from '../Vue/tag.vue'

export default {
    name: 'ContentItem',
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        listLabels: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            searchData: [],
            selectedOwner: {
                ctId: '',
                name: this.$t('mx_mobile.Client.1627009173954') // 全部
            },
            tags: {
                cnFieldCaption: this.$t('mx_mobile.Client.1585277425271'), // 标签
                cnFieldHint: '',
                columnWidth: 120,
                controlTypeId: 'tags',
                dictCode: 11673,
                editState: 1,
                fieldCategory: 3,
                fieldGroup: 0,
                fieldId: 68,
                fieldLength: 10,
                fieldName: 'tags',
                inDefaultValue: '',
                isNotNull: 0,
                moduleCode: 'PS003',
                parFilterField: '',
                stDefaultValue: '',
                strucId: 1,
                uniqueCheck: 0
            }
        }
    },
    created() {
        MXEventBus.$on('selectedOwner', (selectedOwner) => {
            this.selectedOwner.ctId = selectedOwner.ctId
            this.selectedOwner.name = selectedOwner.realName
        })
    },
    mounted() {
        this.getSearchData()
    },
    methods: {
        clear() {
            this.$refs.searchLabelsV.forEach(element => {
                element.clear()
            })
            this.$refs.tag.clear()
            this.selectedOwner = {
                ctId: '',
                name: this.$t('mx_mobile.Client.1627009173954') // 全部
            }
        },
        submit() {
            let obj = {}

            this.$refs.searchLabelsV.forEach(element => {
                Object.assign(obj, element.submit())
            })
            Object.assign(obj, this.$refs.tag.submit())
            this.selectedOwner.ctId && (obj['ownerCtId'] = this.selectedOwner.ctId)
            this.$emit('getListData', obj)
            this.$emit('screenSearchClick', false)
        },
        getSearchData() {
            let data = {
                moduleCode: 'PS003',
                type: 'searchSet'
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    let searchData = res.data.data
                    this.searchData = this.swidchEditSet(searchData)
                    console.log(this.searchData, '---')
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        },
        swidchEditSet(list) { // 编辑字段数据转化
            // let this = this
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                element.controlData = ''
                element.check = ''
                element.inDefaultValue = ''
                if (element.fieldGroup !== 0) { // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldId: element.fieldId,
                            fieldGroup: element.fieldGroup,
                            // cnFieldCaption: this.returnGroupName(element.fieldGroup),
                            cnFieldCaption: '',
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach(function (them) {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        openOwnerSelector() {
            this.$router.push({ name: 'OwnerSelector' })
        }

    },
    components: {
        'searchLabelsV': searchLabelsV,
        'personnel-selection': PersonnelSelection,
        'tag': tag
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
