<template>
    <span v-if="valueData&&valueData!=''" class="titleLabel" :class="'titleLabel'+value">
      {{valueData}}
    </span>
</template>
<script>
export default {
  name: 'titleLabel',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    valueData: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {

  },
  watch: {
  },
  computed: {
  },
  methods: {

  },
  components: {
  }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
