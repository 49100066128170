<template>
    <div class="Home">
        <clueList :sysBoxValue="sysBoxValue"></clueList>
    </div>
</template>
<script>
import clueList from './clueList/index.vue'
import titleMixin from '@/mixin/title'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Home',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.common.1665802470469') }, // '线索'
    computed: {
        ...mapGetters(['sysBoxValue', 'cusBoxValue'])
    },
    data() {
        return {
            msg: 'Home',
            pageUrl: ''
        }
    },
    mounted() {
        // if (this.sysBoxValue && this.sysBoxValue.length == 0) {
        //     this.setSysBoxValue()
        // }
        this.pageUrl = this.$route.path
        this.setMenu()
    },
    methods: {
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.workbench.1584939336363')) // '新增'
            rightMenu.onClick(() => {
                this.openSheet()
                // this.quickAdd()
            })
        },
        async openSheet() {
            try {
                let option = [{
                    name: this.$t('mx_mobile.common.1665806245891'), // '新增线索'
                    index: 0
                }, {
                    name: this.$t('mx_mobile.Client.1585548055236'), // 名片扫描
                    index: 1
                }]
                let res = await this.Global.utils.actionSheet.open(option)
                switch (res.index) {
                    case 0:
                        this.$router.push('/sale/tabs/new/PS003')
                        break
                    case 1:
                        this.$router.push('/sale/tabs/new/PS003/businessCard')
                        break
                    case -1: // 点取消
                        break
                    default:
                        this.$toast.fail({ msg: JSON.stringify(res) })
                        break
                }
            } catch (error) {
                console.log(error)
            }
        }
        // quickAdd() {
        //     this.Global.utils.rightMenu.clearMenu()
        //     this.$router.push({
        //         path: '/sale/tabs/new/PS003'
        //     })
        // }
    },
    watch: {
        $route(val, old) {
            if (val.path.indexOf('/sale/tabs/list/') != -1) {
                this.setMenu()
            }
        }
    },
    components: {
        'clueList': clueList
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
